import {Component, OnInit} from '@angular/core';
import {RouterLink} from "@angular/router";
import {TranslocoDirective, TranslocoPipe} from "@jsverse/transloco";
import {NgOptimizedImage} from "@angular/common";
import {appRoutesLinks} from "../../../app.routes.links";
import {NavigationUtils} from "../../../core/util/navigation.utils";
import {VersionService} from "../../../core/util/version.service";
import {ScrollTopDirective} from "../../directives/scroll-top.directive";
import {ScrollToTopService} from "../../../core/util/scrollToTopService";
@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [RouterLink, ScrollTopDirective, TranslocoDirective, NgOptimizedImage, TranslocoPipe],
  providers: [ScrollToTopService],
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  versionInfo: { version: string; gitHash: string; } | undefined;
  productsLink = appRoutesLinks.PLANT_OVERVIEW_PAGE;
  availabilitiesLink = appRoutesLinks.AVAILABILITY_PAGE;
  catalogsLink = appRoutesLinks.CATALOG_PAGE;
  orderformsLink = appRoutesLinks.ORDERFORM_PAGE;
  geneticsCultureLink = appRoutesLinks.GENETICS_CULTURE_PAGE;
  companyLink = appRoutesLinks.ABOUT_PAGE;
  jobsLink = appRoutesLinks.JOBS_PAGE;
  newsLink = appRoutesLinks.NEWS_PAGE;
  contactLink = appRoutesLinks.CONTACT_PAGE;
  protected readonly appRoutesLinks = appRoutesLinks;

  ngOnInit(): void {
    this.versionInfo = this.versionService.getVersionInfo();
  }

  constructor(private navigationUtils: NavigationUtils,
              private versionService: VersionService) {
  }
  redirectAndClose(route: string[], queryParams: {} = {}): void {
    // First handle the navigation
    if (Object.keys(queryParams).length !== 0) {
      this.navigationUtils.redirect(route, {
        queryParams
      });
    } else {
      this.navigationUtils.redirect(route);
    }

    // Then force scroll to top
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'instant'
      });
    }, 0);
  }

}
